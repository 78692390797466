import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"
import AppIos from "../images/app-ios.svg"
import AppGooglePlay from "../images/app-google-play.svg"
import IconInclusion from "../images/icon-inclusion.svg"
import IconTesting from "../images/icon-testing.svg"
import IconSupport from "../images/icon-support.svg"
import IconHowItWorks from "../images/icon-how-it-works.svg"
import IconAboutUs from "../images/icon-about-us.svg"
import IconBuilding from "../images/icon-building.svg"
import FloorPlan from "../images/floor-plan.svg"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]')
}

const IndexPage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Home" />
      <div class="bk-primary">
      <div id="get-the-app" class="get-the-app wrapper">
        <div class="app-info">
          <p class="section-emphasis">{data.contentIntro.frontmatter.title}</p>
          <p>{data.contentIntro.frontmatter.summary}</p>
        </div>
        <div class="app-links-wrapper">
          {/* remove the next line when the apps are available for download */}
          <p class="small">Available on January 10<sup>th</sup>, 2020</p>
          {/* enable the next line when the apps are available for download */}
          {/* <p class="small">Free to use</p> */}
          <div class="app-links">
            {/* add the link here when the app is available on the App Store */}
            <a href="#">
              <img src={AppIos} alt="Download the app on the app store" />
            </a>
            {/* add the link here when the app is available on Google Play */}
            <a href="#">
              <img src={AppGooglePlay} alt="Download the app on Google Play" />
            </a>
          </div>
        </div>
      </div>
  </div>


      <nav>
        <ul class="nav">
          <li>
            <a href="#how-it-works">How it Works</a>
          </li>
          <li>
            <a href="#for-building-owners">For Building Owners</a>
          </li>
          <li>
            <a href="#about-us">About Us</a>
          </li>
        </ul>
      </nav>

      <div class="wrapper">

      <div
        className="content-intro"
        dangerouslySetInnerHTML={{ __html: data.contentIntro.html }}
      />

      <div
        id="video"
        className="video"
        dangerouslySetInnerHTML={{ __html: data.contentVideo.html }}
      />
      <div id="general-information" class="general-information">
        <h2>{data.contentGeneralInfo.frontmatter.summary}</h2>
        <div class="columns">
          <div
            dangerouslySetInnerHTML={{ __html: data.contentGeneralInfo.html }}
          />
          <div class="image-container">
            <Img
              fluid={data.imagePhoneApp.childImageSharp.fluid}
              alt="person using accessibuild app to navigate a building"
            />
          </div>
        </div>
      </div>

      <div
        id="price-promotion"
        className="price-promotion"
        dangerouslySetInnerHTML={{ __html: data.contentCostsPromotion.html }}
      />

      </div>
      <div class="bk-secondary">
      <div id="product-features" class="product-features wrapper">
        <div class="features">
          <div>
            <img src={IconInclusion} alt="" />
          </div>
          <div>
            <h2>{data.contentFeaturesInclusiveDesign.frontmatter.title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.contentFeaturesInclusiveDesign.html,
              }}
            />
          </div>
        </div>
        <div class="features">
          <div>
            {" "}
            <img src={IconTesting} alt="" />
          </div>
          <div>
            {" "}
            <h2>{data.contentFeaturesTesting.frontmatter.title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.contentFeaturesTesting.html,
              }}
            />
          </div>
        </div>
        <div class="features">
          <div>
            <img src={IconSupport} alt="" />
          </div>

          <div>
            {" "}
            <h2>{data.contentFeaturesSupport.frontmatter.title}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: data.contentFeaturesSupport.html,
              }}
            />
          </div>
        </div>
      </div>
</div>
<div class="wrapper">
      <div class="collaborate">
        <div>
          <Img
            className="imageCollaborate"
            fluid={data.imageCollaborate.childImageSharp.fluid}
            alt="people working together for the greater good"
          />
        </div>

        <div
          dangerouslySetInnerHTML={{ __html: data.contentCollaboration.html }}
        />
      </div>
      <div class="information">
        <div class="image-title">
          <img src={IconHowItWorks} alt="" />
          <h2 id="how-it-works">{data.contentHowItWorks.frontmatter.title}</h2>
        </div>
        <div
          dangerouslySetInnerHTML={{ __html: data.contentHowItWorks.html }}
        />
      </div>

      <div class="information building-owners">
        <div class="image-title">
          <img src={IconBuilding} alt="" />
          <h2 id="for-building-owners">
            {data.contentForBuildingOwners.frontmatter.title}
          </h2>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: data.contentForBuildingOwners.html,
          }}
        />
        <div class="columns">
          <div>
            <img
              className="image-floorplan"
              src={FloorPlan}
              alt="Accessibuild navigates through buildings"
            />
          </div>
          <div>
            <a
              class="button"
              href="mailto:quote@accessibuild.ca?subject=Requesting%20quote%20for%20Accessibuild"
            >
              Request Quote
            </a>
          </div>
        </div>
      </div>

      <div class="information">
        <div class="image-title">
          <img src={IconAboutUs} alt="" />
          <h2 id="about-us">{data.contentAboutUs.frontmatter.title}</h2>
        </div>
        <div dangerouslySetInnerHTML={{ __html: data.contentAboutUs.html }} />
        <a
          class="button contact-us"
          href="mailto:connect@accessibuild.ca?subject=Information%20request%20from%20website"
        >
          Contact Us
        </a>
      </div>
      </div>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    contentIntro: markdownRemark(
      fileAbsolutePath: { regex: "/01-intro-experience-accessibuild.md/" }
    ) {
      frontmatter {
        title
        summary
      }
      html
    }
    contentVideo: markdownRemark(fileAbsolutePath: { regex: "/02-video.md/" }) {
      html
    }
    contentGeneralInfo: markdownRemark(
      fileAbsolutePath: { regex: "/03-general-information.md/" }
    ) {
      frontmatter {
        title
        summary
      }
      html
    }
    contentCostsPromotion: markdownRemark(
      fileAbsolutePath: { regex: "/05-costs-promotion.md/" }
    ) {
      frontmatter {
        summary
      }
      html
    }
    contentFeaturesInclusiveDesign: markdownRemark(
      fileAbsolutePath: { regex: "/06-features-inclusive-design.md/" }
    ) {
      frontmatter {
        title
      }
      html
    }
    contentFeaturesTesting: markdownRemark(
      fileAbsolutePath: { regex: "/07-features-testing.md/" }
    ) {
      frontmatter {
        title
      }
      html
    }
    contentFeaturesSupport: markdownRemark(
      fileAbsolutePath: { regex: "/08-features-support.md/" }
    ) {
      frontmatter {
        title
      }
      html
    }
    contentCollaboration: markdownRemark(
      fileAbsolutePath: { regex: "/09-collaboration.md/" }
    ) {
      frontmatter {
        title
      }
      html
    }
    contentHowItWorks: markdownRemark(
      fileAbsolutePath: { regex: "/10-how-it-works.md/" }
    ) {
      frontmatter {
        title
      }
      html
    }
    contentForBuildingOwners: markdownRemark(
      fileAbsolutePath: { regex: "/11-for-building-owners.md/" }
    ) {
      frontmatter {
        title
      }
      html
    }
    contentAboutUs: markdownRemark(
      fileAbsolutePath: { regex: "/12-about-us.md/" }
    ) {
      frontmatter {
        title
      }
      html
    }

    imagePhoneApp: file(relativePath: { eq: "app-example.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 670) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    imageCollaborate: file(relativePath: { eq: "collaborate.png" }) {
      childImageSharp {
        fluid(maxWidth: 670) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
